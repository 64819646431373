<template>
  <div id="sponsors" v-bind:class="{ 'is-active': isOpen }">
    <div class="has-text-right">
      <button
        aria-label="close"
        class="modal-close is-large"
        v-on:click="isOpen = false"
      ></button>
    </div>
    <h1 class="is-size-4 my-3 px-3">
      Això no seria possible sense tots els
      <span class="is-size-3">nostres amics</span>
    </h1>

    <div class="tile">
      <div class="tile is-child is-4 p-4">
        <img src="../assets/sponsors/2024/alpina.png" />
        <img src="../assets/sponsors/2024/cabirolera.jpg" />
        <img src="../assets/sponsors/2024/comabona.jpg" />
        <img src="../assets/sponsors/2024/ensija.jpg" />
        <img src="../assets/sponsors/2024/figuerassa.jpg" />
        <img src="../assets/sponsors/2024/IMG-20231110-WA0028.jpg" />
        <img src="../assets/sponsors/2024/IMG-20231113-WA0009.jpg" />
        <img src="../assets/sponsors/2024/logo cal candi.png" />
        <img src="../assets/sponsors/2024/volandoo.png" />
      </div>
      <div class="tile is-child is-4 p-4">
        <img src="../assets/sponsors/2024/logo_entrenuvols..jpg" />
        <img src="../assets/sponsors/2024/logofederacioaeri.png" />
        <img src="../assets/sponsors/2024/Logotip-Niviuk.png" />
        <img src="../assets/sponsors/2024/NOva Botiga logo.jpg (3).jpg" />
        <img src="../assets/sponsors/2024/Pedraforca.png" />
        <img src="../assets/sponsors/2024/Profile Picture Xtrem.cat.png" />
        <img src="../assets/sponsors/2024/Puigllançada.jpg" />
      </div>
      <div class="tile is-child is-4 p-4">
        <img src="../assets/sponsors/2024/pirineos.png" />
        <img src="../assets/sponsors/2024/Logo jpg.jpg" />
        <img src="../assets/sponsors/2024/Logo secundari snowlockers.png" />
        <img src="../assets/sponsors/2024/rasos.jpg" />
        <img src="../assets/sponsors/2024/SALEWA_Joined_Black_Logo_77.png" />
        <img src="../assets/sponsors/2024/Screenshot_20231213-110312_WhatsApp.jpg" />
        <img src="../assets/sponsors/2024/strip-air-logo (1).png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sponsors",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
  },
};
</script>

<style lang="scss">
#sponsors {
  box-shadow: 20px 0 20px 10px rgb(0, 0, 0);
  position: fixed;
  right: -1500px;
  top: 0;
  height: 100vh;
  width: 50%;
  background: white;
  z-index: 100;
  transition-property: right;
  transition-duration: 500ms;
  overflow: scroll;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &.is-active {
    right: 0;
  }

  .modal-close {
    position: relative;
    &::after,
    &::before {
      background-color: #4a4a4a;
    }
  }
}
</style>
